import React, {useEffect, useRef, useState} from 'react';
import streetsStrJSON from './streets_str.json';
import streetsObjJSON from './streets_obj.json';
import './StreetsCombobox.scss';
import Popover from '../Popover/Popover';
import {Street} from '../../store/components/realEstatesStore';

type StreetsComboboxProps = {
    initBy?: string | null;
    placeholder?: string;
    onSelectStreet: (street: any) => void;
    onOpen?: () => void;
}

const StreetsCombobox: React.FC<StreetsComboboxProps> = ({initBy, onSelectStreet, onOpen, ...props}) => {
    const streetsComboboxRef = useRef<HTMLInputElement>(null);
    const [state, setState] = useState<any>({
        streets: [],
        searchTerm: '',
        initBy: null,
    });
    const getStreet = (searchTerm: string) => {
        // @ts-ignore
        const street = streetsObjJSON.find((street) => street.presentationAddress === searchTerm);
        return street ? street : null;
    }
    const getStreets = (searchTerm: string) => {
        const match = [];

        // @ts-ignore
        for (let i = 0; i < streetsStrJSON.length && match.length <= 10; i++) {
            // @ts-ignore
            const str = streetsStrJSON[i];
            if (!!~str.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                // @ts-ignore
                match.push(streetsObjJSON[i]);
            }
        }
        return match;
    }
    const merge = (data: any) => setState({...state, ...data});

    useEffect(() => {
        if (initBy) {
            const street = getStreet(initBy);
            merge({streets: getStreets(initBy), initBy: street, searchTerm: street ? street.presentationAddress : ''});
        }

        if (!initBy && state.initBy) {
            merge({streets: [], initBy: null, searchTerm: ''});
        }
    }, [initBy]);

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        if (!searchTerm) {
            return setState({streets: [], searchTerm: '', initBy: null});
        } else {
            return setState({streets: getStreets(searchTerm), searchTerm: searchTerm, initBy: null});
        }
    }

    const handleSelectStreet = (street: any) => {
        merge({streets: [street], searchTerm: street.presentationAddress, initBy: street});
        onSelectStreet(street);
        streetsComboboxRef.current && streetsComboboxRef.current.blur();
    }

    const handleResetStreet = () => {
        merge({streets: [], searchTerm: '', isOpenDropdown: false, initBy: null});
        onSelectStreet(null);
    }

    const handleBlur = () => {
        merge({streets: getStreets(state.searchTerm), searchTerm: state.searchTerm ?? '', initBy: state.initBy ?? null});
        onSelectStreet(state.initBy ?? null);
        streetsComboboxRef.current && streetsComboboxRef.current.blur();
    }

    return (<Popover
        onOutsideClick={handleBlur}
        onClose={handleBlur}
        className="streets-combobox"
        content={<>
            {
                state.searchTerm.length > 0 && state.streets.map((street: Street, index: number) =>
                    <button type="button"
                            data-button-type={'close'}
                            key={street.presentationAddress + '_' + index}
                            onMouseDown={() => handleSelectStreet(street)}
                    >
                        {street.presentationAddress}
                    </button>)
            }
            {
                state.searchTerm.length > 0 && state.streets.length === 0 &&
                <>
                    <div/>
                    <div className="no-match">No match found</div>
                    <div/>
                </>
            }
        </>}>
        <div className="streets-combobox__trigger">
            <input
                autoComplete="off"
                className={`field-street form-control-sm form-control`}
                type="text"
                onChange={handleFilter}
                value={state.searchTerm}
                {...props}
                ref={streetsComboboxRef}
            />
            {
                state.initBy && <i className="icon-close field-postfix" onClick={handleResetStreet}/>
            }
        </div>
    </Popover>)
}

export default StreetsCombobox;
