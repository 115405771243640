import React, {useContext, useEffect, useState} from 'react';
import './RealEstates.scss';
import {observer} from 'mobx-react';
import {realEstatesStoreContext} from '../../store/components/realEstatesStore';
import {Alert, Button, Card, Col, Container, Input, Row, Table} from 'reactstrap';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import RealEstateStatus from '../../components/RealEstateStatus/RealEstateStatus';
import Select, {MultiValue} from 'react-select';
import {clone} from '../../utils/functions';

type StatusOptions = {
    id: number;
    value: string;
    label: string;
}

type Filter = {
    statuses: MultiValue<StatusOptions>;
    phone: string;
    email: string;
    fullName: string;
}

const options: StatusOptions[] = [
    { id: 1, value: 'new', label: 'New' },
    { id: 2, value: 'paused', label: 'Paused' },
    { id: 3, value: 'published', label: 'Published' },
    { id: 4, value: 'closed', label: 'Closed' },
    { id: 5, value: 'sales', label: 'Sales' },
];

const RealEstate = observer(() => {
    const realEstateStoreCtx = useContext(realEstatesStoreContext);
    const [realEstates, setRealEstates] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [filterState, setFilterState] = useState<Filter>({
        statuses: [],
        phone: '',
        email: '',
        fullName: '',
    })
    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoading) {
            return;
        }
        const params = clone(filterState);
        params.statuses = params.statuses.map((status: StatusOptions) => status.value);
        realEstateStoreCtx.loadStore(params).then((result) => {
            setRealEstates(result);
            setLoading(false);
        });
    }, [realEstateStoreCtx, isLoading]);

    const handleNavigateToRealEstateDetails = (id: string) => {
        navigate(`/sell/${id}`);
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setFilterState({...filterState, ...{[field]: event.target.value}});
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setFilterState({...filterState, ...{[field]: event.target.value}});
    }

    const handleChangeStatus = (items: MultiValue<StatusOptions>) => {
        setFilterState({...filterState, ...{statuses: items}});
    }

    const handleApplyFilter = () => {
        setLoading(true);
    }

    const handleResetFilter = () => {
        setFilterState({
            // presentationAddress: '',
            statuses: [],
            phone: '',
            email: '',
            fullName: '',
        });
        handleApplyFilter();
    }

    if (isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    return (<Container>
        <Row>
            <Col sm="12">
                <Card body>
                    <Row className="real-estates-filter align-items-end">
                        <Col sm="2">
                            <label htmlFor="fullName" className="form-label">Client</label>
                            <Input
                                bsSize="sm"
                                id="fullName"
                                name="fullName"
                                type="text"
                                value={filterState.fullName}
                                onChange={(event) => handleChangeTextField(event, 'fullName')}
                            />
                        </Col>
                        {/*<Col sm="2">*/}
                        {/*    <label htmlFor="presentationAddress" className="form-label">Address</label>*/}
                        {/*    <Input*/}
                        {/*        bsSize="sm"*/}
                        {/*        id="presentationAddress"*/}
                        {/*        name="presentationAddress"*/}
                        {/*        type="text"*/}
                        {/*        value={filterState.presentationAddress}*/}
                        {/*        onChange={(event) => handleChangeTextField(event, 'presentationAddress')}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        <Col sm="2">
                            <label htmlFor="email" className="form-label">Email</label>
                            <Input
                                bsSize="sm"
                                id="email"
                                name="email"
                                type="text"
                                value={filterState.email || ''}
                                onChange={(event) => handleChangeTextField(event, 'email')}
                            />
                        </Col>
                        <Col sm="2">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <Input
                                bsSize="sm"
                                id="phone"
                                name="phone"
                                type="text"
                                value={filterState.phone ? +filterState.phone : ''}
                                onChange={(event) => handleChangeTextField(event, 'phone')}
                            />
                        </Col>
                        <Col sm="4">
                            <label htmlFor="status" className="form-label">Status</label>
                            <Select
                                styles={{
                                    // @ts-ignore
                                    control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '31px',
                                        height: '31px'
                                    }),
                                    valueContainer: (provided, state) => ({
                                        ...provided,
                                        height: '31px',
                                        padding: '0 6px'
                                    }),
                                    multiValue: (provided) => ({
                                        ...provided,
                                        marginTop: '0'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        margin: '0px',
                                    }),
                                    indicatorSeparator: state => ({
                                        display: 'none',
                                    }),
                                    indicatorsContainer: (provided, state) => ({
                                        ...provided,
                                        height: '31px',
                                    }),
                                }}
                                isClearable={false}
                                options={options}
                                isMulti
                                id="status"
                                onChange={handleChangeStatus}
                                value={filterState.statuses}
                                placeholder={''}
                            />
                        </Col>
                        <Col className="col-auto">
                            <Button size="sm" type="button" color="success" onClick={handleApplyFilter}>
                                Apply
                            </Button>
                            <Button size="sm" type="button" onClick={handleResetFilter}>
                                Reset
                            </Button>
                        </Col>
                    </Row>
                    {
                        (!realEstates || !realEstates.length) ? <div>
                                <Alert color="warning">
                                    No data for display
                                </Alert>
                            </div>
                            :
                            <Table bordered hover responsive size={'sm'}>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Client</th>
                                    <th>Address</th>
                                    <th>Type</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    realEstates.map((realEstate: any, index: number) => (
                                        <tr key={realEstate.id} onClick={() => handleNavigateToRealEstateDetails(realEstate.id)}>
                                            <td>{realEstate.id}</td>
                                            <td>{realEstate.fullName}</td>
                                            <td>{realEstate.presentationAddress}</td>
                                            <td className="ta-center">
                                                {realEstate.requestType === 'call' && <i className="icon-phone"/>}
                                                {realEstate.requestType === 'mail' && <i className="icon-main"/> }
                                            </td>
                                            <td>{realEstate.phoneCode}{realEstate.phone}</td>
                                            <td>{realEstate.email}</td>
                                            <td>{realEstate.requestDate ? moment(realEstate.requestDate).format('DD MMM YYYY, hh:mm') : ''}</td>
                                            <td>
                                                {realEstate.requestType === 'call' ? realEstate.requestTimeStart ? moment(realEstate.requestTimeStart).format('HH:mm') : '' : ''}
                                                {realEstate.requestType === 'call' && realEstate.requestTimeStart && realEstate.requestTimeEnd ? ' - ' : ''}
                                                {realEstate.requestType === 'call' ? realEstate.requestTimeEnd ? moment(realEstate.requestTimeEnd).format('HH:mm') : '' : ''}
                                            </td>
                                            <td><RealEstateStatus status={realEstate.status}/></td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                    }
                </Card>
            </Col>
        </Row>
    </Container>
    );
})

export default RealEstate;
