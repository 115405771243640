import React, {useContext, useEffect, useState} from 'react';
import './RealEstateDetails.scss';
import {observer} from 'mobx-react';
import {
    conditions, Municipality,
    Preview,
    RealEstate,
    RealEstateExtra,
    realEstatesStoreContext,
    realEstateTypes, Street
} from '../../store/components/realEstatesStore';
import {useParams} from 'react-router-dom';
import {
    Alert,
    Badge,
    Button,
    Card, CardBody, CardHeader,
    CardTitle,
    Col,
    Container,
    Form, FormGroup,
    Input,
    InputGroup, InputGroupText, Label,
    Row
} from 'reactstrap';
import moment from 'moment/moment';
import {clone, extraIconsMap} from '../../utils/functions';
import Editor, {ContentEditableEvent} from 'react-simple-wysiwyg';
import {storeContext} from '../../store/store';
import FieldPhone from '../../components/FieldPhone/FieldPhone';
import StreetsCombobox from '../../components/StreetsCombobox/StreetsCombobox';
import MunicipalitiesCombobox from '../../components/MunicipalitiesCombobox/MunicipalitiesCombobox';

type FileToUpload = {
    id: string;
    preview: string;
    file: File;
}

type ExtraActive = {
    [key: string]: boolean;
}

type RealEstateState = {
    realEstate: RealEstate,
    extras: RealEstateExtra[],
    extrasActive: ExtraActive,
}

const RealEstateDetails = observer(() => {
    let { id } = useParams();
    const storeCtx = useContext(storeContext);
    const realEstateStoreCtx = useContext(realEstatesStoreContext);
    const [isLoading, setLoading] = useState(true);

    const [isSubmitting, setSubmitting] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
    const [filesToRemove, setFilesToRemove] = useState<string[]>([]);
    const [previews, setPreviews] = useState<Preview[]>([]);
    const [mode, setMode] = useState('edit');
    const numberFields = {
        bathroomCount: true,
        bedroomCount: true,
        buildingYear: true,
        plotArea: true,
        livingArea: true,
        finalPrice: true,
        catalogPrice: true,
        clientPrice: true,
        longitude: true,
        latitude: true
    };

    const [realEstateState, setRealEstateState] = useState({
        realEstate: {} as RealEstate,
        extras: [],
        extrasActive: {} as ExtraActive
    });
    const [realEstateDefault, setRealEstateDefault] = useState<RealEstate>({} as RealEstate);

    useEffect(() => {
        // @ts-ignore
        realEstateStoreCtx.getRealEstate(id).then((result) => {
            realEstateStoreCtx.getRealEstateExtras().then((result2) => {
                const realEstateResponse = clone(result);
                setRealEstateState({
                    realEstate: realEstateResponse,
                    extras: result2,
                    extrasActive: result2.reduce((acc: ExtraActive, extra: RealEstateExtra) => {
                        acc[extra.id] = !!([].concat(realEstateResponse.extras) || []).find((id: number) => id === extra.id);
                        return acc;
                    }, {} as RealEstateState)
                })
                setRealEstateDefault(realEstateResponse);
                setPreviews(realEstateResponse.previews ? [].concat(realEstateResponse.previews) : []);
                setLoading(false);
            });
        });
    }, []);

    const getBase64 = async (file: File) => {
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                console.log('Convert to base64 error: ', error);
            };
        })
    }

    const handleAddFilesToLoad = async (event: React.ChangeEvent<HTMLInputElement>) => {
        // Date.now().toString(36) + Math.random().toString(36).substring(2)
        const uploads: FileToUpload[] = [];
        const files = event.target.files;
        if (!files) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const base64 = await getBase64(file);
            uploads.push({
                id: 'preview_image_' + Date.now().toString(36) + Math.random().toString(36).substring(2),
                preview: base64,
                file: file,
            } as FileToUpload)
        }

        setFilesToUpload(filesToUpload.concat(uploads));
        event.target.value = '';
    }

    const handleFilesUpload = async () => {
        const formData = new FormData();
        for (let i = 0; i < filesToUpload.length; i++) {
            const file = filesToUpload[i];
            formData.append(file.id, file.file, file.file.name);
        }
        setSubmitting(true);
        // @ts-ignore
        await realEstateStoreCtx.uploadRealEstateImages(id, formData);
        if (!filesToRemove || !filesToRemove.length) {
            setSubmitting(false);
            window.location.reload();
        }
    }

    const handleFilesRemove = async () => {
        setSubmitting(true);
        // @ts-ignore
        await realEstateStoreCtx.removeRealEstateImages(id, filesToRemove);
        setSubmitting(false);
        window.location.reload();
    }

    const handleRemoveUploaded = (file: Preview) => {
        const files = previews.filter((f: Preview) => f.imagePath !== file.imagePath);
        setPreviews(files);
        setFilesToRemove(filesToRemove.concat([file.imagePath]));
    }
    const handleRemoveFileToUpload = (file: FileToUpload) => {
        const files = filesToUpload.filter((f) => f.id !== file.id);
        setFilesToUpload(files);
    }

    const getRequestTime = () => {
        let time = '';

        if (realEstateState.realEstate.requestTimeStart) {
            time += moment(realEstateState.realEstate.requestTimeStart).format('HH:mm');
        }

        if (realEstate.requestTimeStart && realEstate.requestTimeEnd) {
            time += ' - ';
        }

        if (realEstate.requestTimeEnd) {
            time += moment(realEstate.requestTimeEnd).format('HH:mm');
        }

        return time;
    }

    const merge = (data: any) => {
        const realEstate = {...realEstateState.realEstate, ...data};
        setRealEstateState({...realEstateState, ...{realEstate}});
    }

    const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        merge({[field]: field === 'energyCertificate' ? Boolean(+event.target.value) : event.target.value});
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement> | ContentEditableEvent, field: string) => {
        let value = event.target.value;
        // @ts-ignore
        if (field && numberFields[field]) {
            let regexp = '[^0-9]';
            if (['longitude', 'latitude'].includes(field)) {
                regexp = '[^0-9.-]';
            }
            if (['plotArea', 'livingArea', 'finalPrice', 'catalogPrice', 'clientPrice'].includes(field)) {
                regexp = '[^0-9.]';
            }
            let reg = new RegExp(regexp, 'g');
            value = value.replace(reg, '');
        }

        merge({[field]: value});
    }

    const handleChangeExtraState = (extra: RealEstateExtra) => {
        const extrasActive = {...realEstateState.extrasActive, ...{[extra.id]: !realEstateState.extrasActive[extra.id]}};
        setRealEstateState({...realEstateState, ...{extrasActive}});
    }

    const getExtrasActive = () => {
        return Object.keys(extrasActive).reduce((acc, id) => {
            if (extrasActive[id]) {
                acc.push(+id);
            }
            return acc;
        }, [] as number[]);
    }

    const handleSelectMunicipality = (municipality: Municipality | null) => {
        merge({municipality: municipality ? municipality.name : ''});
    }

    const handleSelectStreet = (addressObj: Street) => {
        merge({
            municipality: addressObj?.municipality ?? '',
            postcode: addressObj?.postcode ?? '',
            street: addressObj?.street ?? '',
            presentationAddress: addressObj?.presentationAddress ?? ''
        });
    }

    const hasSubmitDisabled = () => {
        const defaultStateJSON = JSON.stringify(realEstateDefault);
        const currentState = clone(realEstateState.realEstate);
        currentState.extras = getExtrasActive();
        const currentStateJSON = JSON.stringify(currentState);
        return defaultStateJSON === currentStateJSON && !filesToRemove.length && !filesToUpload.length;
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const state = clone(realEstateState.realEstate);
        for (let key in state) {
            // @ts-ignore
            if (numberFields[key]) {
                const val = parseFloat(state[key]);
                state[key] = val || null;
            }
        }

        if (!state.municipality
            || !state.presentationAddress
            || !state.catalogPrice
            || !state.longitude
            || !state.latitude
            || !realEstate.realEstateMarketingName
            || !realEstate.realEstateMarketingDescription) {
            alert('Fields: Municipality, Address, Catalog price, Longitude, Latitude, Marketing address, Marketing address description required before saving');
            return;
        }

        state.extras = getExtrasActive();
        if (state.status === 'sales') {
            let finalPrice = prompt('Please enter the transaction amount', state.catalogPrice);
            state.finalPrice = finalPrice ? parseFloat(finalPrice) : null;

            if (!state.finalPrice) {
                state.status = realEstateDefault.status;
            }
        }

        if (realEstateDefault.status !== 'published' && state.status === 'published') {
            if (!window.confirm("Attention, transferring an object to Published status will make it available on the site of proposals. Do you really want to do this?")) {
                state.status = realEstateDefault.status;
            }
        }

        await realEstateStoreCtx.updateRealEstate(state.id, state);
        if (!filesToUpload.length && !filesToRemove.length) {
            window.location.reload();
        }
        if (filesToUpload.length > 0) {
            await handleFilesUpload();
        }
        if (filesToRemove.length > 0) {
            await handleFilesRemove();
        }
    }

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!realEstateState.realEstate) {
        return <div>Real estate with id: {id} is empty</div>
    }

    const realEstate = realEstateState.realEstate;
    const extras = realEstateState.extras;
    const extrasActive = realEstateState.extrasActive;
    const municipality = realEstate.municipality;
    const isDisabled = mode === 'static';
    const isDisabledSubmit = hasSubmitDisabled();
    const isAdmin = storeCtx.isAdmin();

    return (<Container>
        <Form onSubmit={handleSubmit}>
            <Card>
                <CardHeader className="card-header-details">
                    <Row>
                        <Col sm="12" md="4" lg="6">
                            ID: {realEstate.id}
                        </Col>
                        <Col sm="8" md="5" lg="5">
                            <Row className="g-2 align-items-center justify-content-between">
                                <Col sm="6" className="ta-right">Status:</Col>
                                <Col sm="6">
                                    <Input
                                        disabled={isDisabled}
                                        bsSize="sm"
                                        id="status"
                                        name="status"
                                        type="select"
                                        defaultValue={realEstate.status}
                                        onChange={(event) => handleChangeSelect(event, 'status')}
                                    >
                                        <option value={'new'}>New</option>
                                        <option value={'paused'}>Paused</option>
                                        {isAdmin ? <option value={'published'}>Published</option> : <option value={'review'}>On review</option>}
                                        {isAdmin && realEstate.status === 'review' && <option value={'review'}>On review</option>}
                                        {!isAdmin && realEstate.status === 'published' && <option value={'published'} disabled={true}>Published</option>}
                                        <option value={'closed'}>Closed</option>
                                        <option value={'sales'}>Sales</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="8" md="3" lg="1">
                            <Button size="sm" type="submit" color={isDisabledSubmit ? "" : "warning"} disabled={isDisabledSubmit}>
                                Update
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>

            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Client information
                        </CardTitle>
                        <Row>
                            <Col sm="3">
                                <Row>
                                    <Col sm="12">
                                        <label htmlFor="fullName" className="form-label">Client</label>
                                        <Input
                                            bsSize="sm"
                                            disabled={isDisabled}
                                            id="fullName"
                                            name="fullName"
                                            type="text"
                                            value={realEstate.fullName}
                                            onChange={(event) => handleChangeTextField(event, 'fullName')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <label htmlFor="requestDate" className="form-label">Request type</label>
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="requestDate"
                                            name="requestDate"
                                            type="text"
                                            value={realEstate.requestType || ''}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="3">
                                <Row>
                                    <Col sm="12">
                                        <label htmlFor="requestDate" className="form-label">Request date</label>
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="requestDate"
                                            name="requestDate"
                                            type="text"
                                            value={realEstate.requestDate ? moment(realEstate.requestDate).format('DD MMM YYYY, hh:mm') : ''}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <label htmlFor="requestTime" className="form-label">Request time</label>
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="requestTime"
                                            name="requestTime"
                                            type="text"
                                            value={getRequestTime()}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="6">
                                <Row>
                                    <Col sm="6">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <Input
                                            bsSize="sm"
                                            disabled={!isAdmin}
                                            id="email"
                                            name="email"
                                            type="text"
                                            value={realEstate.email || ''}
                                            onChange={(event) => handleChangeTextField(event, 'email')}
                                        />
                                    </Col>
                                    <Col sm="6">
                                        <label htmlFor="emailAdditional" className="form-label">Additional email</label>
                                        <Input
                                            bsSize="sm"
                                            id="emailAdditional"
                                            name="emailAdditional"
                                            type="text"
                                            value={realEstate.emailAdditional || ''}
                                            onChange={(event) => handleChangeTextField(event, 'emailAdditional')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <label htmlFor="phone" className="form-label">Phone</label>
                                        <InputGroup size="sm">
                                            <FieldPhone
                                                disabled={!isAdmin}
                                                id="phone"
                                                type="tel"
                                                placeholder={'_________'}
                                                value={realEstate.phone ?? ''}
                                                onChange={(event) => handleChangeTextField(event, 'phone')}
                                                name={'phone'}
                                                maxLength={9}
                                                phoneCode={realEstate.phone ?? ''}
                                                onChangePhoneCode={(phoneCode) => merge({phoneCode: phoneCode})}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col sm="6">
                                        <label htmlFor="phoneAdditional" className="form-label">Additional phone</label>
                                        <InputGroup size="sm">
                                            <FieldPhone
                                                id="phoneAdditional"
                                                type="tel"
                                                placeholder={'_________'}
                                                value={realEstate.phoneAdditional ?? ''}
                                                onChange={(event) => handleChangeTextField(event, 'phoneAdditional')}
                                                name={'phone'}
                                                maxLength={9}
                                                phoneCode={realEstate.phoneCodeAdditional ?? ''}
                                                onChangePhoneCode={(phoneCode) => merge({phoneCodeAdditional: phoneCode})}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-card-description">
                            <Col sm="6">
                                <label htmlFor="description" className="form-label">Request description</label>
                                <Input
                                    disabled
                                    id="description"
                                    name="description"
                                    type="textarea"
                                    value={realEstate.description || ''}
                                    onChange={(event) => handleChangeTextField(event, 'description')}
                                />
                            </Col>
                            <Col sm="6">
                                <label htmlFor="notes" className="form-label">Notes</label>
                                <Input
                                    disabled={isDisabled}
                                    id="notes"
                                    name="notes"
                                    type="textarea"
                                    value={realEstate.notes || ''}
                                    onChange={(event) => handleChangeTextField(event, 'notes')}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Real estate information
                        </CardTitle>
                        <Row>
                            <Col sm="2">
                                <label htmlFor="country" className="form-label">Country</label>
                                <Input
                                    bsSize="sm"
                                    disabled
                                    id="country"
                                    name="country"
                                    type="text"
                                    value={realEstate.country || ''}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="municipality" className="form-label">Municipality</label>
                                {
                                    isAdmin ?
                                        <MunicipalitiesCombobox
                                            initBy={municipality ? municipality : null}
                                            onSelect={handleSelectMunicipality}
                                            icon={'icon-location'}
                                        />
                                        :
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="municipality"
                                            name="municipality"
                                            type="text"
                                            value={realEstate.municipality || ''}
                                        />
                                }
                            </Col>
                            <Col sm="12" md="2" lg="2">
                                <label htmlFor="realEstateType" className="form-label">Type</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="realEstateType"
                                    name="realEstateType"
                                    type="select"
                                    defaultValue={realEstate.realEstateType}
                                    onChange={(event) => handleChangeSelect(event, 'realEstateType')}
                                >
                                    {realEstateTypes.map((realEstateType) => {
                                        return (<option value={realEstateType.value} key={'type_' + realEstateType.id}>
                                            {realEstateType.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            <Col sm="12" md="2" lg="2">
                                <label htmlFor="condition" className="form-label">Condition</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="condition"
                                    name="condition"
                                    type="select"
                                    defaultValue={realEstate.condition}
                                    onChange={(event) => handleChangeSelect(event, 'condition')}
                                >
                                    {conditions.map((condition) => {
                                        return (<option value={condition.value} key={'condition_' + condition.id}>
                                            {condition.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            <Col sm="4">
                                <label htmlFor="presentationAddress" className="form-label">Address</label>
                                {
                                    isAdmin ?
                                        <StreetsCombobox
                                            initBy={realEstate.presentationAddress}
                                            onSelectStreet={handleSelectStreet}
                                        />
                                        :
                                        <Input
                                            bsSize="sm"
                                            disabled
                                            id="presentationAddress"
                                            name="presentationAddress"
                                            type="text"
                                            value={realEstate.presentationAddress || ''}
                                        />
                                }
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="2">
                                <label htmlFor="house" className="form-label">House</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="house"
                                    name="house"
                                    type="text"
                                    value={realEstate.houseNumber ? +realEstate.houseNumber : ''}
                                    onChange={(event) => handleChangeTextField(event, 'houseNumber')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="floor" className="form-label">Floor</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="floor"
                                    name="floor"
                                    type="text"
                                    value={realEstate.floor || ''}
                                    onChange={(event) => handleChangeTextField(event, 'floor')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="ladder" className="form-label">Ladder</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="ladder"
                                    name="ladder"
                                    type="text"
                                    value={realEstate.ladder || ''}
                                    onChange={(event) => handleChangeTextField(event, 'ladder')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="door" className="form-label">Door</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="door"
                                    name="door"
                                    type="text"
                                    value={realEstate.door || ''}
                                    onChange={(event) => handleChangeTextField(event, 'door')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="postcode" className="form-label">Postcode</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="postcode"
                                    name="postcode"
                                    type="text"
                                    value={realEstate.postcode ? +realEstate.postcode : ''}
                                    onChange={(event) => handleChangeTextField(event, 'postcode')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="cadastralNumber" className="form-label">Cadastral number</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="cadastralNumber"
                                    name="cadastralNumber"
                                    type="text"
                                    value={realEstate.cadastralNumber || ''}
                                    onChange={(event) => handleChangeTextField(event, 'cadastralNumber')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="2">
                                <label htmlFor="livingArea" className="form-label">Living area</label>
                                <InputGroup size="sm">
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="livingArea"
                                        name="livingArea"
                                        type="text"
                                        value={realEstate.livingArea ? String(realEstate.livingArea) : ''}
                                        onChange={(event) => handleChangeTextField(event, 'livingArea')}
                                    />
                                    <InputGroupText>
                                        m2
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="plotArea" className="form-label">Plot area</label>
                                <InputGroup size="sm">
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="plotArea"
                                        name="plotArea"
                                        type="text"
                                        value={realEstate.plotArea ? String(realEstate.plotArea) : ''}
                                        onChange={(event) => handleChangeTextField(event, 'plotArea')}
                                    />
                                    <InputGroupText>
                                        m2
                                    </InputGroupText>
                                </InputGroup>
                            </Col>
                            <Col sm="2">
                                <label htmlFor="bedroomCount" className="form-label">Bedrooms</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="bedroomCount"
                                    name="bedroomCount"
                                    type="text"
                                    value={realEstate.bedroomCount ? +realEstate.bedroomCount : ''}
                                    onChange={(event) => handleChangeTextField(event, 'bedroomCount')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="bathroomCount" className="form-label">Bathrooms</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="bathroomCount"
                                    name="bathroomCount"
                                    type="text"
                                    value={realEstate.bathroomCount ? +realEstate.bathroomCount : ''}
                                    onChange={(event) => handleChangeTextField(event, 'bathroomCount')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="2">
                                <label htmlFor="buildingYear" className="form-label">Building year</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="buildingYear"
                                    name="buildingYear"
                                    type="text"
                                    value={realEstate.buildingYear ? +realEstate.buildingYear : ''}
                                    onChange={(event) => handleChangeTextField(event, 'buildingYear')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="energyCetrificate" className="form-label">Energy certificate</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="energyCetrificate"
                                    name="energyCetrificate"
                                    type="select"
                                    defaultValue={typeof realEstate.energyCertificate === 'boolean' ? String(+realEstate.energyCertificate) : '-1'}
                                    onChange={(event) => handleChangeSelect(event, 'energyCertificate')}
                                >
                                    <option disabled value="-1"></option>
                                    {energies.map((energy) => {
                                        return (<option value={energy.value ? '1' : '0'} key={'energy_' + energy.id}>
                                            {energy.name}
                                        </option>)
                                    })}
                                </Input>
                            </Col>
                            {
                                realEstate.energyCertificate === true && realEstate.energyClass &&
                                <Col sm="2">
                                    <label htmlFor="energyCetrificate" className="form-label">Certificate class</label>
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="energyCetrificate"
                                        name="energyCetrificate"
                                        type="select"
                                        defaultValue={realEstate.energyClass ? realEstate.energyClass : '-1'}
                                        onChange={(event) => handleChangeSelect(event, 'energyClass')}
                                    >
                                        <option disabled value="-1"></option>
                                        {energyClasses.map((energyClass) => {
                                            return (<option value={energyClass.value} key={'energyClass_' + energyClass.id}>
                                                {energyClass.name}
                                            </option>)
                                        })}
                                    </Input>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-24">
                            <Col sm="2">
                                <label htmlFor="clientPrice" className="form-label">Client price</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="clientPrice"
                                    name="clientPrice"
                                    type="text"
                                    value={realEstate.clientPrice ? +realEstate.clientPrice : ''}
                                    onChange={(event) => handleChangeTextField(event, 'clientPrice')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="catalogPrice" className="form-label">Catalog price</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="catalogPrice"
                                    name="catalogPrice"
                                    type="text"
                                    value={realEstate.catalogPrice ? +realEstate.catalogPrice : ''}
                                    onChange={(event) => handleChangeTextField(event, 'catalogPrice')}
                                />
                            </Col>
                            {
                                realEstate.status === 'closed' && typeof realEstate.finalPrice === 'number' && realEstate.finalPrice > 0 && <Col sm="2">
                                    <label htmlFor="finalPrice" className="form-label">Final price</label>
                                    <Input
                                        bsSize="sm"
                                        disabled={isDisabled}
                                        id="finalPrice"
                                        name="finalPrice"
                                        type="text"
                                        value={realEstate.finalPrice ? +realEstate.finalPrice : ''}
                                        onChange={(event) => handleChangeTextField(event, 'finalPrice')}
                                    />
                                </Col>
                            }
                            <Col sm="2">
                                <label htmlFor="latitude" className="form-label">Latitude</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="latitude"
                                    name="latitude"
                                    type="text"
                                    value={realEstate.latitude ? String(realEstate.latitude) : ''}
                                    onChange={(event) => handleChangeTextField(event, 'latitude')}
                                />
                            </Col>
                            <Col sm="2">
                                <label htmlFor="longitude" className="form-label">Longitude</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="longitude"
                                    name="longitude"
                                    type="text"
                                    value={realEstate.longitude ? String(realEstate.longitude) : ''}
                                    onChange={(event) => handleChangeTextField(event, 'longitude')}
                                />
                            </Col>
                            {
                                isAdmin && <Col sm="2" className={'best-offer'}>
                                    <FormGroup switch>
                                        <Input
                                            id="best-offer"
                                            type="switch"
                                            checked={realEstate.isBestOffer}
                                            onClick={() => merge({isBestOffer: !realEstate.isBestOffer})}
                                        />
                                        <Label htmlFor="best-offer">Mark is best offer</Label>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row className="mt-16">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingName" className="form-label">Marketing address(a beautiful name for an object in the catalog)</label>
                                <Input
                                    bsSize="sm"
                                    disabled={isDisabled}
                                    id="realEstateMarketingName"
                                    name="realEstateMarketingName"
                                    type="text"
                                    value={realEstate.realEstateMarketingName || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingName')}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-8">
                            <Col sm="12">
                                <label htmlFor="realEstateMarketingDescription" className="form-label">Marketing description(a beautiful text for an object in the catalog)</label>
                                <Editor
                                    id="realEstateMarketingDescription"
                                    name="realEstateMarketingDescription"
                                    value={realEstate.realEstateMarketingDescription || ''}
                                    onChange={(event) => handleChangeTextField(event, 'realEstateMarketingDescription')} />
                            </Col>
                        </Row>
                        <Row className="mt-24">
                            <Col sm="12">
                                <Row>
                                    <Col sm="12">
                                        Extras
                                    </Col>
                                </Row>
                                <div className="user-extras">
                                    {extras.map((extra: RealEstateExtra) => {
                                        const isActive = extrasActive[extra.id];

                                        if (isActive) {
                                            return <Badge color={'success'} key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                                {/*@ts-ignore*/}
                                                <i className={`${extraIconsMap[extra.id]}`}/>
                                                {extra.name}
                                            </Badge>
                                        }

                                        return (<Badge color={'light'} className="text-dark" key={'extra_' + extra.id} onClick={() => handleChangeExtraState(extra)}>
                                            {/*@ts-ignore*/}
                                            <i className={`${extraIconsMap[extra.id]}`}/>
                                            {extra.name}
                                        </Badge>);
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="details-card">
                <Col sm="12">
                    <Card body>
                        <CardTitle tag="h4">
                            Preview images
                        </CardTitle>
                        <div className="previews">
                            {previews && previews.length === 0 && filesToUpload.length === 0 && <Alert color="warning">
                                No images for display
                            </Alert>}
                            {previews && previews.length > 0 &&  previews.map((f) => <div className="preview preview-uploaded" key={f.imageUrl}>
                                <img src={f.imageUrl} alt='preview'/>
                                <i className="icon-close" onClick={() => handleRemoveUploaded(f)}/>
                            </div>)}
                            {filesToUpload.length > 0 && filesToUpload.map((f) => <div className="preview preview-queue-upload" key={f.id}>
                                <img src={f.preview} alt='preview'/>
                                <i className="icon-close" onClick={() => handleRemoveFileToUpload(f)}/>
                            </div>)}
                        </div>
                        <input type="file" id="files" multiple onChange={handleAddFilesToLoad} accept="image/png, image/jpeg"/>
                        <label className="btn btn-secondary" htmlFor="files">
                                Choose files
                        </label>
                    </Card>
                </Col>
            </Row>
                </CardBody>
            </Card>
        </Form>
    </Container>);
})

export type Energy = {
    id: number;
    name: string;
    value: boolean
}

export type EnergyClass = {
    id: number;
    name: string;
    value: string
}

const energyClasses: EnergyClass[] = [
    {id: 1, name: 'A', value: 'A'},
    {id: 2, name: 'B', value: 'B'},
    {id: 2, name: 'C', value: 'C'},
    {id: 2, name: 'D', value: 'D'},
    {id: 2, name: 'E', value: 'E'},
    {id: 2, name: 'F', value: 'F'},
    {id: 2, name: 'G', value: 'G'},
]

const energies: Energy[] = [
    {id: 1, name: 'Yes', value: true},
    {id: 2, name: 'No', value: false},
]

export default RealEstateDetails;
